import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Meal = ({ meal, image, id }) => {
  return (
    <div key={meal.period} id={`meal-${id}`}>
      <h3>{meal.period}</h3>
      <h4>{meal.name} [{meal.quantity.find(q => q.energy === '2500kcal')?.mass}]</h4>
      {image && <GatsbyImage image={getImage(image)} alt={meal.name} loading={id ? 'lazy' : 'eager'} style={{background: 'hsl(0 0% 97%)'}} />}
      <ul style={{ columnWidth: 180 }}>
        {meal.ingredients.map(ingredient => (
          <li key={ingredient}>
            {ingredient}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Meal
