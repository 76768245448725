import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Meal from '../components/Meal'

const monthsList = {
  ian: { last: 31 },
  feb: { last: 28 },
  mar: { last: 31 },
  apr: { last: 30 },
  mai: { last: 31 },
  iun: { last: 30 },
  iul: { last: 31 },
  aug: { last: 31 },
  sep: { last: 30 },
  oct: { last: 31 },
  nov: { last: 30 },
  dec: { last: 31 },
}

const getPrevDay = date => {
  let month = date.split(' ')[1] // current month
  let day = Number(date.split(' ')[0]) - 1 // prev day
  if (day <= 0) {
    // prev month, last day
    const monthIndex = Object.keys(monthsList).indexOf(month)
    month = Object.keys(monthsList)[monthIndex - 1] || 'dec'
    day = monthsList[month].last
  }
  return { day, month }
}
const getNextDay = date => {
  let month = date.split(' ')[1] // current month
  let day = Number(date.split(' ')[0]) + 1 // next day
  if (day > monthsList[month].last) {
    // next month
    const monthIndex = Object.keys(monthsList).indexOf(month)
    month = Object.keys(monthsList)[monthIndex + 1] || 'ian'
    day = 1
  }
  return { day, month }
}
const getDayLink = date =>
  `/meals/${date.month}-${String(date.day).padStart(2, '0')}`

export default function Day({ data }) {
  const { date, day, meals } = data.mealsJson || {}
  const images = []
  data.allFile.edges
    .map(_ => _.node)
    .forEach(image => {
      images[image.name.substr(-1)] = image
    })
  return (
    <Layout>
      <h6>
        <Link to={getDayLink(getPrevDay(date))}>Ziua precedentă</Link> -{' '}
        <Link to="/">Azi</Link> -{' '}
        <Link to={getDayLink(getNextDay(date))}>Ziua următoare</Link>
      </h6>
      <h1>
        {day}, {date}
      </h1>
      {meals?.map((meal, i) => (
        <Meal key={meal.period} meal={meal} image={images[i]} id={i} />
      ))}
    </Layout>
  )
}
export const query = graphql`
  query($id: String!, $nameRegex: String) {
    mealsJson(parent: { id: { eq: $id } }) {
      date
      day
      meals {
        image
        ingredients
        name
        period
        quantity {
          energy
          mass
        }
      }
    }

    allFile(
      filter: {
        relativeDirectory: { eq: "images" }
        name: { regex: $nameRegex }
      }
    ) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`
